.ant-layout-footer {
    padding: 24px 32px;
}

@media screen and (max-width: 767px) {
    .ant-layout-footer {
        padding: 18px 16px;
        text-align: center;
    }
}
