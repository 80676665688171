.ant-popover-message {
    display: flex;
    align-items: center;
}

.ant-popover-title {
    border: none;
    padding: 8px 16px;
}

.ant-popover-inner-content {
    padding: 16px;
}

.ant-popover-buttons .ant-btn {
    padding: 0 16px;
}

.ant-popover-buttons .ant-btn:nth-child(1) {
    color: var(--b-60);
    border-color: var(--b-60);
    background-color: var(--b-20);
}

.ant-popover-message .anticon svg {
    width: 16px;
    height: 16px;
    fill: var(--primary-1);
}
.variables-popover-overlay > .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content {
    padding-top: 0px;
}
