:root {
    --font-weight-extraLight: 200;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semiBold: 600;
    --font-weight-bold: 700;
    --font-weight-extraBold: 800;
    --h1-font-size: 42px;
    --h1-line-height: 48px;
    --h2-font-size: 28px;
    --h3-font-size: 24px;
    --h4-font-size: 20px;
    --h5-font-size: 16px;
    --caption: 12px;
    --p1-body: 14px;
    --p2-body: 14px;
    --button: 14px;
    --placeholder: 14px;
    --input-description: 10px;
    --input-label: 14px;
    --badge-text: 12px;
    --h1-line-height: 48px;
    --h2-line-height: 42px;
    --h3-line-height: 28px;
    --h4-line-height: 24px;
    --h5-line-height: 24px;
    --caption-line-height: 18px;
    --p1-body-line-height: 21px;
    --p2-body-line-height: 21px;
    --button-line-height: 16px;
    --placeholder-line-height: 16px;
    --input-description-line-height: 12px;
    --input-label-line-height: 16px;
    --badge-text-line-height: 12px;
}

@font-face {
    font-family: "Manrope";
    src: url("../fonts/Manrope-ExtraLight.ttf") format("truetype");
    font-weight: var(--font-weight-extraLight, 200);
    font-style: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("../fonts/Manrope-Light.ttf") format("truetype");
    font-weight: var(--font-weight-light, 300);
    font-style: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("../fonts/Manrope-Regular.ttf") format("truetype");
    font-weight: var(--font-weight-regular, 400);
    font-style: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("../fonts/Manrope-Medium.ttf") format("truetype");
    font-weight: var(--font-weight-medium, 500);
    font-style: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("../fonts/Manrope-SemiBold.ttf") format("truetype");
    font-weight: var(--font-weight-semiBold, 600);
    font-style: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("../fonts/Manrope-Bold.ttf") format("truetype");
    font-weight: var(--font-weight-extraLight, 700);
    font-style: normal;
}

@font-face {
    font-family: "Manrope";
    src: url("../fonts/Manrope-ExtraBold.ttf") format("truetype");
    font-weight: var(--font-weight-extraLight, 800);
    font-style: normal;
}
