.da-content-main {
    margin: 0 32px;
    min-height: calc(100vh - 173px);
}

@media screen and (max-width: 991px) {
    .da-header-search {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -70px;
        top: auto;
    }
    .da-header-search-result {
        top: calc(100% - 10px);
        border-radius: 16px;
        right: 0;
    }
}

.ant-layout-header {
    /* background-color: @b-0; */
    border-radius: 7px;
    display: flex;
    align-items: center;
    z-index: 3;
}

.da-header-search {
    opacity: 0;
    visibility: hidden;
    transform: translate(-30px, 0);
    transition: 0.4s;
}

.da-header-search-header-search-active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
}

.da-header-search-header-search-active-result {
    position: absolute;
    top: 100%;
    left: 0;
    right: 10px;
    /* background: @b-0; */
    border-radius: 0 0 16px 16px;
    overflow: hidden;
    line-height: normal;
}
.da-header-search-header-search-active-result ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.da-header-search-header-search-active-result li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.da-header-search-header-search-active-result li a {
    margin: 0;
    padding: 0;
    list-style: none;
}

.da-update-icon {
    min-width: 18px;
    border-radius: 9px;
}
.test-attributes-table-row {
    display: grid;
}
.ant-transfer-list {
    border: none !important;
}
.failed {
    background-color: var(--danger-1);
}
.passed {
    background-color: var(--success-1);
}
.info-not-connect {
    color: var(--danger-1);
}
.info-connect {
    color: var(--success-1);
}
.hp-d-block {
    word-wrap: break-word;
}
