.tour-img {
    width: 100px !important;
}

.ant-tour-mask {
    z-index: 1003 !important;
}

.tour-button-right {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    z-index: 1;
    right: 0;
    width: 50px;
}