.da-elevatior {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
}

.da-elevatior-2 {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.ant-card:not(.ant-card-bordered) {
    box-shadow: none;
}
