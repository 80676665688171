.ant-alert {
    padding: 16px;
    width: 100%;
}
.ant-alert .ant-alert-icon {
    margin-right: 20px;
}
.ant-alert .ant-alert-close-icon .anticon-close {
    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
}
.ant-alert .ant-alert-close-icon .anticon-close svg {
    display: none;
}
.ant-alert-success {
    background-color: var(--success-5);
    border: 1px solid var(--success-5);
}
.ant-alert-success .ant-alert-content .ant-alert-message,
.ant-alert-success .ant-alert-content .ant-alert-description {
    color: var(--success-1);
}
.ant-alert-success .ant-alert-close-icon .anticon-close {
    background-image: url(../../../assets/images/alert/success-close.svg);
}
.ant-alert-info .ant-alert-content .ant-alert-message,
.ant-alert-info .ant-alert-content .ant-alert-description {
    color: var(--info-1);
}
.ant-alert-info .ant-alert-close-icon .anticon-close {
    background-image: url(../../../assets/images/alert/info-close.svg);
}
.ant-alert-warning .ant-alert-content .ant-alert-message,
.ant-alert-warning .ant-alert-content .ant-alert-description {
    color: var(--warning-1);
}
.ant-alert-warning .ant-alert-close-icon .anticon-close {
    background-image: url(../../../assets/images/alert/warning-close.svg);
}
.ant-alert-error .ant-alert-content .ant-alert-message,
.ant-alert-error .ant-alert-content .ant-alert-description {
    color: var(--danger-1);
}
.ant-alert-error .ant-alert-close-icon .anticon-close {
    background-image: url(../../../assets/images/alert/error-close.svg);
}
