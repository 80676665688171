.switch-button {
    width: max-content !important;
    display: grid;
}
.switch-button-red {
    width: max-content !important;
    display: grid;
    background: var(--danger-1) !important;
}
.upload-button {
    height: 20px;
}
.ant-btn,
.ant-btn-primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: initial;
    height: auto;
    min-height: 40px;
    box-shadow: none !important;
}

.ant-btn > .remix-icon {
    line-height: 1;
    font-size: 16px;
}
.ant-modal-confirm-btns > .ant-btn-default:hover {
    border-color: var(--primary-2);
    color: var(--primary-2);
}

.ant-btn-link {
    background: transparent;
    border: none;
}

.ant-btn-icon-only {
    background: transparent;
    border: none;
}

#open_url_req:not(.ant-btn-disabled),
#open_url_bug:not(.ant-btn-disabled),
#open_url_tc:not(.ant-btn-disabled),
#get-test-report-button-url-root:not(.ant-btn-disabled),
#rerun-button-root:not(.ant-btn-disabled),
.create_bug_action {
    color: var(--primary-1);
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
    border-color: transparent !important;
}

.ant-btn-ghost {
    color: var(--primary-1);
    border-color: var(--primary-1);
}

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
    background-color: var(--primary-1) !important;
    color: var(--b-0) !important;
}

.button-tag {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 10px;
    min-height: fit-content;
    border: none !important;
    white-space: nowrap !important;
}
.button-tag-checkable-failed {
    color: var(--b-0);
    border: none !important;
    font-weight: 600;
    background-color: var(--danger-1);
}
.button-tag-checkable-failed:hover,
.button-tag-checkable-failed:focus {
    background-color: var(--danger-2) !important;
    color: white !important;
}

.button-action-update {
    font-size: 12px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    background-color: var(--b-60) !important;
    color: var(--b-0);
    height: fit-content;
}
.button-action-update:hover,
.button-action-update:focus {
    background-color: var(--b-80) !important;
    color: var(--b-0) !important;
}
.tableButton {
    background-color: var(--primary-4);
}
.tableButton:hover,
.tableButton:focus {
    background-color: var(--b-80) !important;
    color: white !important;
}
.ant-btn > svg.remix-icon {
    margin-right: 10px;
}
.forgot-button-link {
    margin-bottom: 20px;
}
.dq_disabled_button {
    min-height: auto;
}
#logs_button > svg.remix-icon {
    margin-right: 0px !important;
}

#test-connetion-button path {
    stroke: currentColor;
}
.buttonWithHoverColorChanged {
    background: var(--primary-1);
}
.ant-btn-compact-last-item.ant-dropdown-trigger:not(:disabled) {
    background: var(--primary-1);
}
