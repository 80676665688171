:root {
    --input-focus: 0px 0px 10px rgba(10, 175, 255, 0.35);
    --primary-shadow: 0px 0px 25px rgba(0, 16, 247, 0.7);
    --primary-1: #ccac5c;
    --primary-2: #636e72;
    --primary-4: #f4eedb;
    /* table expanded row */
    --primary-5: #f5f2eb;

    --primary-6: #fffefc;
    /* intent in table */
    --primary-7: #f4f2eb;
    /* hover in table light */
    --primary-8: #f5f5f5;
    /* hover in table dark */
    --primary-8-dark: #1f1f1f;
    --primary-9: #dcdcdc;
    --primary-9-dark: #111314;

    --secondary-1: #b09398;
    --secondary-2: #9b6a6c;
    --secondary-3: #f7c2ff;
    --secondary-4: #fdeffc;
    --danger-1: #c65664;
    --danger-2: #a54d5a;
    --danger-3: #ff8b9a;
    --danger-4: #ffe7ea;
    --danger-5: #b87880;
    --danger-6: #f7e7e9;
    --success-1: #005b4d;
    --success-2: #cedfd9;
    --success-3: #98ffe0;
    --success-4: #eafff8;
    --success-5: #d6e2df;
    --info-1: #949494;
    --info-2: #b2bec3;
    --info-3: #97f4ff;
    --info-4: #eafcff;
    --b-0: #ffffff;
    --b-10: #f4eedb;
    --b-20: #f0f3f5;
    --b-40: #e2d9bf;
    --b-60: #b2bec3;
    --b-80: #636e72;
    --b-100: #2d3436;
    --dark-bg: #111314;
}
