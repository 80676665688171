.ant-layout-header {
    /* background-color: @b-0; */
    margin: 16px 16px 16px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    z-index: 3;
}
@media screen and (max-width: 991px) {
    .da-header-search {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -70px;
        top: auto;
    }
    .da-header-search-result {
        top: calc(100% - 10px);
        border-radius: 16px;
        right: 0;
    }
}
.da-header-search {
    opacity: 0;
    visibility: hidden;
    transform: translate(-30px, 0);
    transition: 0.4s;
}
.da-header-search.da-header-search-active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
}
.da-header-search-result {
    position: absolute;
    top: 100%;
    left: 0;
    right: 10px;
    /* background: @b-0; */
    border-radius: 0 0 16px 16px;
    overflow: hidden;
    line-height: normal;
}
.da-header-search-result > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.da-header-search-result > ul > li {
    height: auto;
    transition: 0.4s;
    font-size: 12px;
    line-height: normal;
}
.da-header-search-result > ul > li > a {
    display: block;
    padding: 10px 40px;
    /* color: @b-100; */
}
.da-update-icon {
    min-width: 18px;
    border-radius: 9px;
}

.da-mobile-sidebar-button {
    display: none;
}

.da-header-left-text-item {
    line-height: 32px;
}
.da-header-left-text-item.remix-icon {
    vertical-align: text-bottom;
}

@media screen and (max-width: 1380px) {
    .da-header-left-text-item {
        line-height: normal;
    }
}

@media screen and (max-width: 1199px) {
    .da-mobile-sidebar-button {
        display: block;
    }

    .da-header-left-text {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .ant-layout-header {
        margin: 16px 16px 32px;
        padding: 0 16px 0 8px;
    }
}

.da-basket-dropdown-button:hover.da-basket-dropdown {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}

.da-basket-dropdown {
    position: absolute;
    min-width: 330px;
    padding: 35px 16px 8px;
    top: 30px;
    right: -10px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.4s;
}

.da-basket-dropdown.remove-cart {
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
}
.da-basket-dropdown-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 358px;
}
.da-basket-dropdown-list::-webkit-scrollbar {
    width: 0;
}
.da-basket-dropdown:before {
    content: "";
    position: absolute;
    top: 29px;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: @b-0; */
    /* border: 1px solid @b-40; */
    border-radius: 8px;
}
.da-basket-dropdown > div {
    position: relative;
}
@media screen and (max-width: 767px) {
    .da-basket-dropdown {
        right: 0;
        left: 10%;
        transform: translate(-50%, 0px);
        min-width: 300px;
    }
}

.today-text {
    margin-left: 20px;
    font-size: large;
}

.ant-layout-header {
    margin-left: 0px !important;
    background-color: transparent !important;
}
.da-profile-menu-header {
    display: grid;
    justify-content: center;
    -webkit-justify-content: center;
}
