.ant-table-cell {
    text-align: center !important;
}

.description-column {
    overflow-wrap: anywhere !important;
}
.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}
.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

div.ant-table-header > table > colgroup > col.ant-table-selection-col {
    width: 3.5% !important;
}
div.ant-table-body > table > colgroup > col.ant-table-selection-col {
    width: 2% !important;
}
div.ant-table-header > table > colgroup > col.ant-table-expand-icon-col {
    width: 3.5% !important;
}
div.ant-table-body > table > colgroup > col.ant-table-expand-icon-col {
    width: 2% !important;
}
.child-table {
    margin-left: 4%;
}
.ant-scrolling-effect {
    width: calc(100%) !important;
}

.virtual-table-cell {
    padding: 18px 16px;
}
@media screen and (max-width: 1199px) {
    .da-table-content-col {
        flex: 0 0 calc(-90%);
        max-width: calc(-90%);
    }
}
@media screen and (max-width: 991px) {
    .da-table-content-col {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.da-table-menu .ant-menu {
    width: 200px;
    margin-top: 0;
    padding: 10px 0;
    border-radius: 8px;
    border: 1px solid var(--b-40);
}
.da-table-menu .ant-menu li {
    height: 30px;
    line-height: normal;
    padding: 0px !important;
}
.da-table-menu span.ant-menu-title-content > span {
    padding: 0 14px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    transition: 0.4s;
}
.da-table-menu .ant-menu li {
    background: none !important;
    overflow: hidden;
    color: #636e72;
}
.da-table-menu span.ant-menu-title-content {
    height: 100%;
}
.da-table-menu span.ant-menu-title-content > span:after {
    content: "";
    position: absolute;
    top: 8px;
    bottom: 8px;
    right: 5px;
    border-radius: 2px;
    padding: 8px 0;
    border-right: 2px solid var(--primary-1);
    transform: scaleY(0.0001);
    opacity: 0;
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.da-table-menu span.ant-menu-title-content > span.active:after {
    opacity: 1;
    transform: scale(1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.da-table-menu span.ant-menu-title-content > span.active {
    color: var(--primary-1);
    background: var(--primary-4);
}
.da-table-menu span.ant-menu-title-content > span > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.da-table-menu .ant-menu li:after {
    visibility: hidden;
}
@media screen and (max-width: 1450px) {
    .da-table-menu .ant-menu {
        width: 150px;
    }
}
@media screen and (max-width: 991px) {
    .da-table-menu {
        display: none;
    }
}
.failed-row {
    background: var(--danger-4);
    border-radius: 8px 8px 8px 8px;
}
.failed-light-row-dark {
    background: var(--danger-2);
    border-radius: 8px 8px 8px 8px;
}
.failed-row-dark {
    background: var(--danger-5);
    border-radius: 8px 8px 8px 8px;
}
.ant-table-row-expand-icon-cell,
.ant-table-selection-column {
    width: 2%;
}
.table-my-wrapper .table {
    table-layout: fixed !important; /* rewrite inline styles */
}
