@media screen and (max-width: 767px) {
    .ant-drawer.da-drawer-mobile .ant-drawer-content-wrapper {
        width: 85% !important;
    }
}
.da-drawer-submit .ant-drawer-content-wrapper {
    max-width: 720px;
}
@media screen and (max-width: 991px) {
    .da-drawer-submit .ant-drawer-content-wrapper {
        max-width: 60%;
    }
}
@media screen and (max-width: 767px) {
    .da-drawer-submit .ant-drawer-content-wrapper {
        max-width: 90%;
    }
}
.ant-drawer-header-title > button {
    margin-top: 0px;
}
