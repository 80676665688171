.da-authentication-page {
    position: relative;
    height: 100%;
    min-height: 100vh;
    margin: 0 !important;
    overflow: hidden;
}
.icon-logo-auth {
    margin-top: 64px !important;
    margin-left: 64px !important;
}

.da-authentication-page .da-bg-item {
    transform: translate(0px, -10%);
}
.da-authentication-page .da-text-item {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
@media screen and (max-width: 767px) {
    .da-authentication-page .da-text-item {
        position: relative;
        top: 0%;
        left: 0;
        transform: translate(0, 0);
        margin-top: -30%;
    }
}
.da-authentication-page .da-or-line {
    position: relative;
}
.da-authentication-page .da-or-line:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    transform: translate(0, -50%);
    background-color: ;
    z-index: -1;
}
.da-authentication-page .da-other-links a + a {
    position: relative;
    padding-left: 16px;
    margin-left: 16px;
}
.da-authentication-page .da-other-links a + a:before {
    content: "";
    position: absolute;
    top: 49%;
    left: 0;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background-color: ;
}
