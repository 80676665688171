.ant-pagination {
    text-align: center;
}

.ant-pagination-options-quick-jumper {
    input {
        &:focus {
            border-color: var(--primary-2);
            border-right-width: 1px !important;
            outline: 0;
            box-shadow: var(--input-focus);
        }
    }

    input-focused {
        border-color: var(--primary-2);
        box-shadow: var(--input-focus);
    }
}

.ant-pagination-item {
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.ant-pagination-prev {
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.ant-pagination-next {
    margin-top: 16px;
    margin-bottom: 16px;
}

.ant-pagination-jump-prev {
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.ant-pagination-jump-next {
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.ant-pagination-options {
    margin-top: 16px;
    margin-bottom: 16px;
}

.ant-pagination-simple {
    .ant-pagination-simple-pager {
        margin-right: 16px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

.ant-pagination.mini .ant-pagination-item {
    margin: 0 4px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
}

.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
    line-height: 28px;
    margin: 0;
    font-size: 14px;
}

.ant-pagination.mini .ant-pagination-options {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 4px;
}

.ant-table-pagination {
    align-items: center;
}

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    border-color: @b-40;
}

.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
    height: 32px;
    min-width: 32px;
    margin: 0 4px;
}

@media screen and (max-width: 767px) {
    .ant-pagination-item {
        margin-right: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
        min-width: 30px;
        height: 30px;
        line-height: 28px;
    }

    .ant-pagination-jump-next {
        margin-right: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .ant-pagination-prev {
        margin-right: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .ant-pagination-next {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .ant-pagination-options {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        min-width: 30px;
        height: 30px;
        line-height: 28px;
    }
}
