.ant-input:focus,
.ant-input-focused {
    border-color: var(--primary-2);
    box-shadow: var(--input-focus);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    outline: 0;
    box-shadow: var(--input-focus);
}

.ant-input-prefix {
    margin-right: 8px;
    font-size: 16px;
    color: var(--b-80);
}

.ant-input-suffix {
    margin-left: 8px;
    font-size: 16px;
    color: var(--b-80);
}

.ant-input-textarea-show-count::after {
    font-size: var(--input-description);
}

.ant-input-affix-wrapper-sm {
    padding: 4px 15px;
}

.ant-input-number:focus,
.ant-input-number-focused {
    border-color: var(--primary-2);

    box-shadow: var(--input-focus);
}

.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
    color: var(--b-80);
}

.ant-form-item-has-warning .ant-input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
    box-shadow: 0px 0px 10px rgba(255, 194, 18, 0.35);
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
    box-shadow: 0px 0px 10px rgba(255, 0, 34, 0.35);
}

.ant-input-group-addon {
    background-color: var(--primary-5);
    color: var(--b-80);
    font-family: "Manrope", sans-serif !important;
}

.da-xl-search-button .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    padding: 0 64px;
}

#aggregation_name_1 {
    border-radius: 0 0 0 0;
}

#aggregation_name_2 {
    border-radius: 0 8px 8px 0;
}
