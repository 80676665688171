.ant-menu-item {
    display: flex;
    align-items: center;
}

.ant-menu-submenu-title {
    display: flex;
    align-items: center;
}

.ant-menu-item-only-child {
    padding-left: 56px;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
    text-align: left !important;
}

.ant-menu-vertical .ant-menu-item {
    text-align: center;
}

.ant-menu-submenu-arrow {
    width: 24px;
}

.ant-menu-item-group-title {
    font-weight: var(--font-weight-bold);
    padding: 12px 24px;
}

.da-sidebar > .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 calc(50% - 24px / 2) !important;
}

.da-sidebar > .ant-layout-sider-collapsed > .ant-layout-sider-children > .ant-row:first-child {
    margin-right: 20px !important;
    margin-left: 20px !important;
}

.da-sidebar > .ant-layout-sider-collapsed > .ant-layout-sider-children > .ant-menu {
    margin-top: 14px;
}

.da-sidebar > .ant-layout-sider-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .remix-icon,
.ant-menu-submenu-title .remix-icon {
    min-width: 24px;
}

.da-sidebar > .ant-layout-sider-children {
    position: sticky;
    top: 0;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.da-sidebar > .ant-layout-sider-children > .ant-row:nth-child(1) > .ant-btn {
    padding: 0;
    overflow: hidden;
}
.da-sidebar > .ant-layout-sider-children > .ant-row:nth-child(1) > .ant-btn > svg {
    width: 100%;
    height: 100%;
}

.da-sidebar > .ant-layout-sider-children > .ant-row:nth-child(1) > .ant-btn > svg > g {
    transform: scale(0.4);
    transform-origin: center;
}
.da-sidebar > .ant-layout-sider-children > .ant-menu-root {
    position: relative;
    min-height: calc(100vh - 154px);
    max-height: calc(100vh - 154px);
    flex: 1 1;
    padding-bottom: 16px;
}
.ant-menu.ant-menu-root {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100vh;
    position: sticky;
    top: 0px;
}
.ant-menu.ant-menu-root::-webkit-scrollbar {
    width: 0;
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
    text-align: center;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .remix-icon {
    margin: 0;
    font-size: 24px;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
    font-size: 24px;
}

.da-logo {
    height: 40px;
}

.da-mobile-sidebar {
    display: none;
}
.da-mobile-sidebar > .ant-drawer-header {
    border: none;
}

@media screen and (max-width: 1199px) {
    .da-mobile-sidebar {
        display: block;
    }

    .da-sidebar.ant-layout-sider {
        display: none;
    }

    .da-mobile-sidebar.ant-menu.ant-menu-root.ant-menu-inline {
        margin-top: 0;
        position: relative;
        padding-bottom: 24px;
        height: 100%;
    }

    .da-mobile-sidebar.ant-drawer-body {
        padding: 18px 4px 0;
        display: flex;
        flex-direction: column;
    }
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% - 20px);
    margin-left: 10px;
    border-radius: 7px;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    top: 8px;
    bottom: 8px;
    right: 5px;
    border-radius: 2px;
    padding: 8px 0;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-left: 24px !important;
}

.ant-menu-item-selected-in-active, 
.ant-menu-submenu-selected-in-active .ant-menu-submenu-title {
    color: inherit !important;
    background-color: transparent !important;
}
.ant-menu-item-selected-in-active:after {
    transform: scaleY(0.0001) !important;
    opacity: 0 !important;
}
.ant-menu-item-selected-in-active > a {
    color: inherit !important;
    background-color: transparent !important;
}
