.test-data-tag {
    font-family: "Manrope", sans-serif !important;
    margin: 5px !important;
    white-space: break-spaces;
}

.summaryTag {
    margin: 5px;
}

.ant-tag {
    padding: 0 16px;
    border: 1px solid transparent !important;
    margin-bottom: 10px;
    font-weight: bold;
}

.suggestedTag {
    border: 2px dashed var(--primary-1) !important;
}

.noBorderTagWhite {
    color: var(--b-0);
    border: 1px solid transparent !important;
}

.tag-break-spaces {
    white-space: break-spaces;
}

.connectionTag-info {
    margin-bottom: 10px;
}
.tag-update {
    background-color: var(--b-60);
    color: var(--b-0);
    height: fit-content;
}
.tag-update:hover {
    background-color: var(--b-80);
    color: var(--b-0) !important;
}
.tag-running {
    color: white;
    border: none !important;
    font-weight: 700;
    background-color: #97b1aa !important;
}
.tag-running:hover {
    color: var(--b-0) !important;
}

.tag-failed {
    color: white;
    border: none !important;
    font-weight: 700;
    background-color: var(--danger-1);
}
.tag-failed:hover {
    color: var(--b-0) !important;
}
.tag-failed-no-hover {
    color: white;
    border: none !important;
    font-weight: 700;
    background-color: var(--danger-1);
}
.tag-error-no-hover {
    color: white;
    border: none !important;
    font-weight: 700;
    background-color: var(--danger-2);
}
.tag-failed-no-hover:hover {
    background-color: var(--danger-1);
}
.tag-passed {
    color: white;
    border: none !important;
    font-weight: 600;
    background-color: var(--success-1);
}
.tag-warning {
    color: white;
    border: none !important;
    font-weight: 600;
    background-color: #b0aa9b;
}
.tag-info {
    color: white;
    border: none !important;
    font-weight: 600;
    background-color: var(--primary-1);
}

.tag-info-checkable {
    color: white;
    border: none !important;
    font-weight: 600;
    background-color: var(--primary-1);
}
.tag-info-checkable:hover {
    background: var(--info-1);
    color: var(--primary-2) !important;
}

.ant-tag-magenta {
    background: var(--secondary-4);
    border-color: var(--secondary-1);
    color: var(--secondary-1);
}

.ant-tag-red {
    background: var(--danger-4);
    border-color: var(--danger-1);
    color: var(--danger-1);
}

.ant-tag-primary {
    background: var(--primary-2);
    border-color: var(--primary-2);
    color: var(--primary-4);
}

.ant-tag-gold {
    background: var(--primary-4);
    border-color: var(--primary-4);
    color: var(--primary-2);
}

.ant-tag-cyan {
    background: var(--info-4);
    border-color: var(--info-1);
    color: var(--info-1);
}

.ant-tag-green {
    background: var(--success-4);
    border-color: var(--success-1);
    color: var(--success-1);
}

.ant-tag-success {
    background: var(--success-2);
    border-color: var(--success-1);
    color: var(--success-1);
}

.ant-tag-processing {
    background: var(--primary-4);
    border-color: var(--primary-1);
    color: var(--primary-1);
}

.ant-tag-error {
    background: var(--danger-4);
    border-color: var(--danger-1);
    color: var(--danger-1);
}

.ant-tag-warning {
    background: var(--warning-4);
    border-color: var(--warning-1);
    color: var(--warning-1);
}
.tag-checkable {
    background: var(--b-20);
}
.tag-checkable:hover {
    background-color: var(--primary-2);
    color: white !important;
}
.tag-checkable-primary {
    background-color: var(--b-20);
    color: #636e72 !important;
    margin-bottom: 0;
}
.tag-checkable-primary:hover {
    background-color: var(--primary-2);
    color: white !important;
}
.tag-checkable::selection,
.tag-checkable:checked {
    background-color: var(--primary-2) !important;
    color: #636e72 !important;
}
.tag-checkable-checkbox {
    background-color: var(--primary-2);
    color: white !important;
    margin-bottom: 0;
}
.tag-checkable-checkbox:hover {
    background-color: black;
}
.ant-tag-checkable-checked {
    background-color: var(--primary-1) !important;
}

.tag-checkable-status {
    background-color: var(--b-20);
    margin-bottom: 0;
}
.tag-checkable-status-dark {
    background-color: var(--b-80);
    margin-bottom: 0;
}
.tag-checkable-status:hover,
.tag-checkable-status-dark:hover {
    background-color: var(--primary-2) !important;
}
.tag-checkable-status::selection,
.tag-checkable-status:checked,
.tag-checkable-status-dark::selection,
.tag-checkable-status-dark:checked {
    background-color: var(--primary-2) !important;
    color: white !important;
}

.text-hover-link-dark:hover,
.text-hover-link:hover {
    color: var(--primary-1) !important;
}
.tag-suggested {
    background: transparent;
    color: var(--info-1);
    border: 1px dashed var(--primary-1) !important;
}
