.ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-modal-close-x svg {
    margin-right: 2px;
}
.ant-modal {
    width: 50% !important;
}
.modal_test_case {
    width: 80% !important;
}
.popup-modal .ant-modal-body {
    padding-bottom: 24px;
}
.ant-modal-body {
    padding-top: 20px;
}

.ant-modal-confirm-paragraph {
    width: 100%;
}

@media only screen and (min-width: 840px) {
    .ant-modal,
    .ant-modal-1200 {
        min-width: 800px !important;
    }
}

@media only screen and (min-width: 1240px) {
    .ant-modal-1200 {
        min-width: 1200px !important;
    }
}

.ant-modal-70 {
    width: 70% !important;
}

.ant-modal-20 {
    width: 20% !important;
}
@media only screen and (min-width: 840px) {
    .ant-modal-confirm {
        min-width: unset !important;
    }
}
.delete-confirm-modal,
.modal-confirmation {
    width: 400px !important;
}
.delete-confirm-modal .ant-modal-confirm-content {
    max-width: 100% !important;
}
.delete-confirm-modal-icon {
    color: var(--danger-1);
}
.modal-confirmation-icon {
    color: var(--primary-1);
}
