.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-left: 40px !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-item-selected {
    margin-left: 10px !important;
}
.ant-menu-horizontal .ant-menu-item svg {
    vertical-align: middle;
    margin-top: -2px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none;
}

.ant-menu-submenu-arrow {
    right: 0;
}
.da-profile-menu {
    border: none;
}
.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical,
.ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: none;
}
