.ant-dropdown-trigger.ant-dropdown-link {
    display: inline-flex;
    align-items: center;
    color: var(--primary-1);
}
.ant-dropdown-trigger.ant-dropdown-link svg {
    margin-left: 10px;
}

.ant-dropdown-menu {
    padding: 8px 6px;
    border: none !important;
}

.ant-dropdown {
    border: none !important;
}

.ant-dropdown-menu-submenu-popup {
    border: none !important;
}

.ant-dropdown-menu-item:hover {
    background-color: var(--primary-4);
}
.ant-dropdown-menu-submenu-title:hover {
    background-color: var(--primary-4);
}
