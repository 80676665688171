#basic_source_object,
#basic_source_object_schema,
#basic_source_column,
#basic_comparison_object_schema,
#basic_comparison_object,
#basic_target_comparison_object,
#basic_target_object_schema {
    display: initial;
}
.join_column_badge {
    width: 100%;
}

.dq-panel > .ant-collapse-item > .ant-collapse-header {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
}

.full_width_badge {
    width: 100%;
}
#metric_column_executions_dq_check_stats {
    display: grid;
    justify-content: end;
}

.dq-chart-tooltip-collapse .ant-collapse-content-box {
    padding: 4px 16px !important;
}
