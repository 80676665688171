.ant-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-4);
    color: var(--primary-1);
}
#documentation-header-item-avatar {
    margin-right: 0px;
}

.ant-avatar > svg {
    width: 100%;
    height: 60%;
}
.ant-avatar-circle {
    margin: 5px;
}
.avatar-failed {
    background-color: var(--danger-1);
    color: white;
    margin-top: 7%;
    margin-left: 7%;
}
.avatar-passed {
    background-color: var(--success-1);
    color: white;
    margin-top: 7%;
    margin-left: 7%;
}

.avatar-failed-without-margin {
    background-color: var(--danger-1);
    color: white;
}

.avatar-passed-without-margin {
    background-color: var(--success-1);
    color: white;
}

.avatar-sm {
    width: 20px;
    height: 20px;
}
