.ant-popover-buttons .ant-btn:nth-child(1) {
    color: var(--b-60);
    border-color: var(--b-60);
    background-color: var(--b-20);
}

.ant-btn-loading-icon .anticon {
    margin-right: 8px;
}

.da-placement {
    overflow-x: auto;
    overflow-y: hidden;
}
.da-placement .ant-btn {
    text-align: center;
    padding: 0 8px;
    min-width: 82px;
    height: 40px;
}
.ant-popconfirm-buttons {
    display: flex;
}
