.da-icon-card,
.da-icon-card:hover {
    box-shadow: var(--input-focus);
}
.icon-failed {
    color: var(--danger-5) !important;
}
.icon-failed > .ant-btn {
    color: var(--danger-5) !important;
}
.icon-passed {
    color: var(--success-1) !important;
}
.icon-hover:hover {
    box-shadow: 0px 0px 15px var(--primary-1);
}
