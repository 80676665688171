.ant-checkbox-inner {
    border-radius: 4px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    border-radius: 2px;
}

.ant-checkbox-inner::after {
    top: 47%;
    left: 19%;
}
