.ant-picker-calendar-full,
.ant-picker-panel {
    overflow: scroll;
}

.events {
    margin: 0;
    padding: 0;
    list-style: none;
}

.events .ant-badge-status {
    width: 100%;
    border: 1px solid var(--danger-1);
    background-color: var(--danger-4);
    border-radius: 12px;
    overflow: hidden;
    padding-left: 4px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.events .ant-badge-status-dot {
    width: 10px;
    height: 10px;
}

.notes-month {
    font-size: 28px;
    text-align: center;
}

.notes-month section {
    font-size: 28px;
}

.calendar-demo-card {
    max-width: 300px;
    border: 1px solid var(--b-40);
    border-radius: 7px;
    overflow: scroll;
}
