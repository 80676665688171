.ant-message-custom-content .anticon {
    margin-right: 10px;
}

.ant-message-notice-content {
    border-radius: 2px;
}

.ant-message-notice .ant-message-custom-content span:nth-child(2) {
    font-weight: 300;
    color: var(--b-80);
}

.ant-message-notice .ant-message-loading svg {
    fill: var(--primary-1);
}

.ant-message-notice .ant-message-success svg {
    fill: var(--success-1);
}

.ant-message-notice .ant-message-info svg {
    fill: var(--info-1);
}

.ant-message-notice .ant-message-error svg {
    fill: var(--danger-1);
}

.ant-message-notice .ant-message-warning svg {
    fill: var(--warning-1);
}
