.select_with_no_dropdown {
    display: none !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: var(--input-focus);
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
}
.text-title {
    word-wrap: break-word;
}
