.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::before,
.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
    display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    display: flex;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .da-collapse-arrow {
    transition: 0.4s;
}

.ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-extra .da-collapse-arrow {
    transform: rotate(90deg);
}

@media screen and (max-width: 767px) {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 24px !important;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 16px 24px !important;
    }
}
.ant-collapse-header {
    padding: 20px !important;
}
.prediction-collapse > .ant-collapse-content {
    display: none;
}
