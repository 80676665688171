.head-example {
    width: 42px;
    height: 42px;
    border-radius: 7px;
    background: var(--primary-4);
    display: inline-block;
    vertical-align: middle;
}

.da-badge-16.ant-badge,
.da-badge-16 .ant-badge-status-dot {
    width: 16px;
    height: 16px;
}

.da-badge-height-16 {
    height: 16px;
}

.badge-calendar-today .ant-badge-dot {
    top: 4px;
    right: 5px;
}
.ant-badge.ant-badge-status .ant-badge-status-error {
    background-color: var(--danger-1) !important;
}

