.start-node {
    background-color: var(--primary-1);
}
.flow-builder-zoom-tool,
.flow-builder-undo-redo-tool {
    display: contents;
}
.flow-builder-zoom-tool > button > span,
.flow-builder-zoom-tool__number,
.flow-builder-undo-redo-tool > button > span {
    color: #666;
}

.end-node {
    background-color: #666;
}
.ant-drawer-close {
    margin-top: 20px;
}
.start-node,
.end-node {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    line-height: 64px;
    color: #fff;
    text-align: center;
}

.other-node,
.condition-node {
    border-radius: 4px;
    color: #666;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
}

.other-node,
.condition-node {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.flow-builder-vertical .flow-builder-branch-node__add-button {
    top: -15px !important;
}
.hover-icon:hover {
    background-color: var(--primary-5);
}
.scheduleSelectBox {
    background-color: var(--primary-4) !important;
    color: #141414 !important;
}
.scheduleSelectBox-dark {
    background-color: var(--primary-9-dark);
    color: var(--primary-2) !important;
}
.selected-test-suite {
    background-color: var(--primary-8);
    color: var(--primary-2) !important;
}
.selected-test-suite-dark {
    background-color: var(--primary-8-dark);
}
#execution-test_case_search {
    font-size: small;
}
.ant-input-search-button > span {
    font-size: small;
}

.flow-builder-addable-nodes > .ant-popover-content > .ant-popover-inner-content:hover {
    color: rgba(0, 0, 0, 0.08) !important;
}
.flow-builder-addable-node-item:hover,
.flow-builder-addable-node-item > span:hover {
    color: var(--dark-bg);
}
.react-flow > .react-flow__attribution.bottom.right {
    display: none;
}
#runtime_variables_search {
    font-size: 14px;
}
