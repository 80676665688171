.da-profile-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #e2d9bf;
    min-height: 930px;
}
.da-profile-menu-header .ant-scroll-number {
    bottom: -7px;
    right: 6px;
    top: auto;
    padding: 0 3px;
    height: 16px;
    line-height: 16px;
    z-index: 1;
    transform: translate(0px, 0px);
    box-shadow: none;
}
.da-profile-menu-header .ant-avatar {
    overflow: initial;
}
.da-profile-menu-header .ant-avatar:before {
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
    background: conic-gradient(#ccac5c, #fff 0);
    border-radius: 100%;
    pointer-events: none;
}
.da-profile-menu-header .ant-avatar:after {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    background: #ffffff;
    border-radius: 100%;
    pointer-events: none;
}
.da-profile-menu-header .ant-avatar img {
    position: relative;
    z-index: 1;
}
.da-profile-menu-body {
    margin-top: 50px !important;
}
.da-profile-menu-body .is-active .ant-row {
    color: #ccac5c;
}
.da-profile-menu-body .is-active svg {
    fill: #ccac5c;
}
@media screen and (max-width: 991px) {
    .da-profile-menu {
        display: none;
    }
}
