h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: var(--font-weight-bold);
    margin-top: 0px;
    margin-bottom: 0px;
}

h1,
.h1 {
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
}

h2,
.h2 {
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
}

h3,
.h3 {
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
}

h4,
.h4 {
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);
}

h5,
.h5 {
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
}

.da-caption {
    font-size: var(--caption);
    line-height: var(--caption-line-height);
    font-weight: var(--font-weight-bold);
}

.da-p1-body {
    font-size: var(--p1-body);
    line-height: var(--p1-body-line-height);
    font-weight: var(--font-weight-regular);
    margin-right: 10px;
}

.da-p2-body {
    font-size: var(--p2-body);
    line-height: var(--p2-body-line-height);
    color: var(--text-color-dark);
    font-weight: var(--font-weight-extraLight);
}

.da-button {
    font-size: var(--button);
    line-height: var(--button-line-height);
    color: var(--text-color-dark);
    font-weight: var(--font-weight-medium);
}

.da-placeholder {
    font-size: var(--placeholder);
    line-height: var(--placeholder-line-height);
    color: var(--text-color-dark);
    font-weight: var(--font-weight-light);
}

.da-input-description {
    font-size: var(--input-description);
    line-height: var(--input-description-line-height);
    color: var(--text-color-dark);
    font-weight: var(--font-weight-semiBold);
}

.da-input-label {
    font-size: var(--input-label);
    line-height: var(--input-label-line-height);
    color: var(--text-color-dark);
    font-weight: var(--font-weight-semiBold);
}

.da-badge-text {
    font-size: var(--badge-text);
    line-height: var(--badge-text-line-height);
    font-weight: var(--font-weight-semiBold);
}
.da-badge-text:hover {
    color: var(--text-color-dark);
}

.da-text-center {
    text-align: center;
}

.da-text-lowercase {
    text-transform: lowercase;
}

.da-text-uppercase {
    text-transform: uppercase;
}

.da-text-capitalize {
    text-transform: capitalize;
}

.da-text-line-through {
    text-decoration: line-through;
}

.da-text-underline {
    text-decoration: underline;
}

.da-font-weight-200 {
    font-weight: var(--font-weight-extraLight);
}

.da-font-weight-300 {
    font-weight: var(--font-weight-light);
}

.da-font-weight-400 {
    font-weight: var(--font-weight-regular);
}

.da-font-weight-500 {
    font-weight: var(--font-weight-medium);
}

.da-font-weight-600 {
    font-weight: var(--font-weight-semiBold);
}

.da-font-weight-700 {
    font-weight: var(--font-weight-bold);
}

.da-font-weight-800 {
    font-weight: var(--font-weight-extraBold);
}

.da-color-inherit {
    color: inherit;
}
.da-pre-line {
    white-space: pre-line;
}
.text-12 {
    font-size: 12px !important;
}
