.ant-tree {
    width: 100%;
}

.ant-tree-treenode {
    align-items: center !important;
    height: 100% !important;
}

.ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-tree-treenode-selected::before {
    display: none !important;
}

.ant-tree-switcher {
    height: auto !important;
}