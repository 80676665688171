/* @spacing: ~"0" 0px, ~"4" 4px, ~"6" 6px, ~"8" 8px, ~"10" 10px, ~"12" 12px, ~"16" 16px, ~"18" 18px, ~"24" 24px, ~"32" 32px, ~"36" 36px,
    ~"42" 42px, ~"48" 48px, ~"64" 64px, ~"96" 96px, ~"120" 120px; */

.da-pl-16 {
    padding-left: 16px;
}
.da-mb-0 {
    margin-bottom: 0 !important;
}
.da-mt-4 {
    margin-top: 4px !important;
}