body {
    overflow-x: hidden;
    margin: 0 !important;
}
img {
    user-select: none;
    max-width: 100%;
}
.da-m-auto {
    margin: auto !important;
}
.da-m-0 {
    margin: 0 !important;
}
.da-p-0 {
    padding: 0 !important;
}

.da-pl-8 {
    padding-left: 8px !important;
}
.da-pl-24 {
    padding-left: 24px !important;
}

.da-pr-8 {
    padding-right: 8px !important;
}

.da-ml-8 {
    margin-left: 8px !important;
}
.da-mr-8 {
    margin-right: 8px !important;
}

.da-img-cover {
    object-fit: cover;
}

.da-img-contain {
    object-fit: contain;
}
.da-scrollbar-y-hidden::-webkit-scrollbar {
    width: 0;
}

.da-scrollbar-x-hidden::-webkit-scrollbar {
    height: 0;
}
.da-modal-p-24.ant-modal-body {
    padding: 24px;
}

.da-line-height-normal {
    line-height: normal;
}

.da-overflow-hidden {
    overflow: hidden;
}

.da-overflow-scroll {
    overflow: scroll;
}

.da-d-flex {
    display: flex;
}

.da-flex-wrap {
    flex-wrap: wrap;
}

.da-d-flex-center {
    display: flex !important;
    align-items: center;
}

.da-d-flex-full-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.da-d-flex-end {
    display: flex;
    justify-content: flex-end;
}

.da-d-flex-between {
    justify-content: space-between;
}

.da-d-block {
    display: block;
}

.da-d-inline {
    display: inline;
}

.da-d-inline-block {
    display: inline-block;
}

.da-d-inline-flex {
    display: inline-flex;
}

.da-position-relative {
    position: relative;
}

.da-position-absolute {
    position: absolute;
}

.da-position-absolute-top-left {
    position: absolute;
    top: 0;
    left: 0;
}

.da-position-absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.da-position-absolute-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
}

.da-position-absolute-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.da-w-auto {
    width: auto !important;
}

.da-w-100 {
    width: 100% !important;
}
@media screen and (max-width: 1599px) {
    .da-text-xl-left {
        text-align: left !important;
    }
    .da-text-xl-right {
        text-align: right !important;
    }
    .da-text-xl-center {
        text-align: center !important;
    }
}
@media screen and (max-width: 1199px) {
    .da-text-lg-left {
        text-align: left !important;
    }
    .da-text-lg-right {
        text-align: right !important;
    }
    .da-text-lg-center {
        text-align: center !important;
    }
}
@media screen and (max-width: 991px) {
    .da-text-md-left {
        text-align: left !important;
    }
    .da-text-md-right {
        text-align: right !important;
    }
    .da-text-md-center {
        text-align: center !important;
    }
}
@media screen and (max-width: 767px) {
    .da-text-sm-left {
        text-align: left !important;
    }
    .da-text-sm-right {
        text-align: right !important;
    }
    .da-text-sm-center {
        text-align: center !important;
    }
}
@media screen and (max-width: 575px) {
    .da-text-xs-left {
        text-align: left !important;
    }
    .da-text-xs-right {
        text-align: right !important;
    }
    .da-text-xs-center {
        text-align: center !important;
    }
}

.da-text-center {
    text-align: center;
}

.da-text-right {
    text-align: right;
}

.da-text-left {
    text-align: left;
}

.da-h-auto {
    height: auto !important;
}

.da-h-100 {
    height: 100% !important;
}

.da-font-18 {
    font-size: 18px;
}
.da-vertical-align-bottom {
    vertical-align: bottom;
}

.da-vertical-align-middle {
    vertical-align: middle !important;
}

.da-align-self-center {
    align-self: center;
}

.da-align-items-center {
    align-items: center;
}

.da-align-items-end {
    align-items: flex-end;
}

.da-z-index {
    z-index: 1;
}

.da-cursor-pointer {
    cursor: pointer;
}

.da-transition {
    transition: 0.4s;
}

code {
    padding: 4px;
    border-radius: 4px;
    background: #fff0;
    margin: 0 1px;
}

.show-code {
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 20px);
    transition: 0.5s;
}
.show-code-active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.scroll-to-top > div {
    z-index: 3;
}

.scroll-button-top {
    border: none !important;
    min-height: 50px !important;
    background: var(--primary-1) !important;
    min-width: 50px !important;
}

.scroll-button-top:hover {
    background: var(--primary-2) !important;
}

.scroll-button-top > svg {
    min-height: 25px !important;
    min-width: 25px !important;
}

.da-grid-row-bg {
    background-image: linear-gradient(
        90deg,
        #b2bec3 4.16666667%,
        transparent 0,
        transparent 8.33333333%,
        #b2bec3 0,
        #b2bec3 12.5%,
        transparent 0,
        transparent 16.66666667%,
        #b2bec3 0,
        #b2bec3 20.83333333%,
        transparent 0,
        transparent 25%,
        #b2bec3 0,
        #b2bec3 29.16666667%,
        transparent 0,
        transparent 33.33333333%,
        #b2bec3 0,
        #b2bec3 37.5%,
        transparent 0,
        transparent 41.66666667%,
        #b2bec3 0,
        #b2bec3 45.83333333%,
        transparent 0,
        transparent 50%,
        #b2bec3 0,
        #b2bec3 54.16666667%,
        transparent 0,
        transparent 58.33333333%,
        #b2bec3 0,
        #b2bec3 62.5%,
        transparent 0,
        transparent 66.66666667%,
        #b2bec3 0,
        #b2bec3 70.83333333%,
        transparent 0,
        transparent 75%,
        #b2bec3 0,
        #b2bec3 79.16666667%,
        transparent 0,
        transparent 83.33333333%,
        #b2bec3 0,
        #b2bec3 87.5%,
        transparent 0,
        transparent 91.66666667%,
        #b2bec3 0,
        #b2bec3 95.83333333%,
        transparent 0
    );
    margin-bottom: 8px;
    overflow: hidden;
}

.da-grid-col {
    border: none;
    /* color: @b-0; */
    font-size: 18px;
    line-height: 2;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    opacity: 0.75;
    padding: 30px 0px;
}

.da-mark > ul > li {
    list-style-type: circle;
    margin-left: 20px;
    padding-left: 4px;
}

.da-color-wrapper-xl {
    height: 60px;
    width: 90px;
}

.da-color-wrapper-sm {
    height: 20px;
    width: 30px;
}

.da-color-wrapper {
    height: 60px;
    width: 60px;
}

.border {
    border: 1px solid var(--b-40);
}
.anticon-copy,
.primary_link {
    color: var(--primary-1);
}
.primary_link:hover {
    color: var(--primary-2);
}

.ant-transfer-operation > button {
    background: var(--primary-1);
}

.ant-transfer-operation > button:hover {
    color: var(--primary-1);
}

.card-padding-16 > .ant-card-body {
    padding: 16px !important;
}

.card-padding-10 > .ant-card-body {
    padding: 10px !important;
}

.card-padding-8 > .ant-card-body {
    padding: 8px !important;
}

.card-padding-0 > .ant-card-body {
    padding: 0 !important;
}

.col-flex-ai-center {
    display: flex;
    align-items: center;
}
.col-flex-ji-center {
    display: flex;
    justify-content: center;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}
.font-size-14 {
    font-size: 14px !important;
}
