.title-text {
    font-size: large;
}

.text-container {
    justify-content: center;
}
.ant-menu-item-icon {
    width: 20px;
}
.card-tests {
    margin-top: 20px;
    margin-bottom: 50px;
}
.tests-space {
    display: block;
}
.test-attributes-col {
    display: flex;
    align-items: center !important;
}

.test-attributes-row {
    display: contents;
    grid-template-columns: 33%;
    justify-content: center;
}

.button-placeholder-right {
    margin-bottom: 20px;
    display: grid;
    -webkit-justify-content: flex-end;
    justify-content: end;
}

.button-placeholder-left {
    margin-bottom: 20px;
    display: grid;
    -webkit-justify-content: flex-start;
    justify-content: start;
}
#today-date {
    font-size: large;
    margin-left: 2%;
}
