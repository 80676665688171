.da-slider-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}
.da-slider-icon-wrapper .ant-slider {
    flex: 1;
    margin: 0 14px;
}
.da-slider-icon-wrapper .anticon {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 1;
}
.da-slider-icon-wrapper .anticon:first-child {
    left: 0;
}
.da-slider-icon-wrapper .anticon:last-child {
    right: 0;
}
.da-slider-icon-wrapper .icon-wrapper-active {
    color: rgba(0, 0, 0, 0.45);
}

.dq-threshold-slider .ant-slider-handle-1:after {
    box-shadow: 0 0 0 4px #ccac5c !important;
}

.dq-threshold-slider .ant-slider-handle-2:after {
    box-shadow: 0 0 0 4px #c65664 !important;
}