@media screen and (max-width: 767px) {
    .ant-list-lg .ant-list-item {
        padding: 16px 0 16px;
    }
    .ant-list-item-meta {
        flex: 0 0 100%;
        margin-bottom: 24px;
    }
}
.test-keywords-list > .ant-list-item-meta-content > .ant-list-item-meta-title,
.test-keywords-no-params-list > .ant-list-item-meta-content > .ant-list-item-meta-title {
    display: flex;
}

.custom-keyword-list-item {
    border-block-end: none !important;
    padding-bottom: 0px !important;
}

.custom-keyword-list-item  .ant-list-item-meta-content {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.custom-keyword-list-item > .test-keywords-list > .ant-list-item-meta-content > .ant-list-item-meta-title {
    white-space: nowrap;
    flex: 0 1 auto;
}

.custom-keyword-list-item .ant-list-item-meta-description {
    display: grid;
    flex: 1;
    overflow-x: auto;
}